$grid-columns: 12;
$grid-gutter-width: 30px;

$grid-breakpoints: (
    // Extra small screen / phone
    xs: 0,
    // Small screen / phone
    s: 390px,
    sm: 576px,
    // Medium screen / tablet
    md: 768px,
    // Large screen / desktop
    lg: 992px,
    // Extra large screen / wide desktop
    xl: 1200px
);

$container-max-widths: (
    s: 370px,
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px
);