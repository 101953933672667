* {
  box-sizing: border-box !important;
  }

html {
  background-color: #000 ;
}

.root {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

body {
  background: #000;
  color: #000000;
  font-size: 14px;
  font-family: 'Raleway', sans-serif;
  line-height: 1.80857;
  font-weight: normal;
  overflow-x: hidden;
}

a {
  color: #1f1f1f;
  text-decoration: none !important;
  outline: none !important;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  letter-spacing: 0;
  font-weight: normal;
  position: relative;
  padding: 0;
  font-weight: normal;
  line-height: normal;
  color: #fffefe;
  margin: 0;
}

h1 {
  font-size: 24px;
}

h2 {
  font-size: 32px;
  color: white;
  text-align: center;
  margin: 0px auto;
  font-weight: 900;
  padding-top: 15px;
}

h3 {
  font-size: 20px;
  font-weight: 700;
  columns: white;
}

h4 {
  font-size: 16px;
}

h5 {
  font-size: 18px;
  color: white;
  text-align: center;
  margin: 0px auto;
}

h6 {
  font-size: 13px;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  &::after,
  &::before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: #212121;
  text-decoration: none !important;
  opacity: 1;
}

button:focus {
  outline: none;
}

ul,
li,
ol {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

p {
  margin: 0px;
  font-weight: 300;
  font-size: 15px;
  line-height: 24px;
}

a {
  color: #222222;
  text-decoration: none;
  outline: none !important;
  color: white;
  text-decoration: none !important;
  outline: none !important;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.btn {
  color: white;
  text-decoration: none !important;
  outline: none !important;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: white;
  }
}

.cancel_btn {
  text-decoration: underline !important;
  font-weight: 600;
  width: 85px;
  margin: 0 auto;
}

.sign_btn {
  text-decoration: underline !important;
  font-weight: 600;
}

.menu_icon-login {
  background-color: #b3a063 !important;
  border-radius: 6px;
  width: 105px;
  height: 38px;
  margin: 0 auto;
}

.demo {
  min-width: 100px;
  padding: 5px;
  min-height: 38px;
  margin: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--colors-phoenix-7, #b3a063);
  gap: 8px;
  border-radius: 6px;
  border: 2px solid #b3a063;
  margin-right: 10px;

  &:hover {
    color: #fff;
    font-style: normal;
    line-height: 18px;

    /* 75% */
  }
}

.play {
  background: var(--colors-phoenix-7, #b3a063);
  min-width: 100px;
  padding: 5px;
  min-height: 38px;
  margin: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  border: 2px solid #b3a063;
  text-decoration: none;
  font-weight: 700;

  &:hover {
    background: rgba(179, 160, 99, 0.5);
    color: #fff;
    font-style: normal;
  }
}

.icon-margin {
  margin-right: 10px;
}

.btn-group {
  margin-top: 10px;
  flex-direction: inherit;
  justify-content: center;
}

img {
  max-width: 100%;
  height: auto;
}

:focus {
  outline: 0;
}

.btn-custom {
  margin-top: 20px;
  background-color: transparent !important;
  border: 2px solid #ddd;
  padding: 12px 40px;
  font-size: 16px;
}

.lead {
  font-size: 18px;
  line-height: 30px;
  color: #767676;
  margin: 0;
  padding: 0;
}

.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

form label {
  color: white;
  display: flex;
  font-weight: 300;
}

label::after {
  content: ' *';
  color: white;
  font-size: 16px;
}

form {
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.sign-title {
  color: white;
  font-size: 40px;
  font-weight: 600;
  justify-content: center;
  text-align: center;
  margin: 0px auto;
  font-weight: 900;
  padding-bottom: 35px;
  padding-top: 76px;
}

.form_control {
  margin: 0 auto;
  display: block;
}

.sign_form {
  margin-bottom: 32px;
  margin-top: 5px;
}

.form-control:focus {
  border-color: #ffffff !important;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.25);
}

.navbar-form input {
  border: none !important;
}

.badge {
  font-weight: 500;
}

blockquote {
  margin: 20px 0 20px;
  padding: 30px;
}

button {
  border: 0;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.full {
  float: left;
  width: 100%;
}

.layout_padding {
  padding-top: 90px;
  padding-bottom: 90px;
}

.layout_padding_2 {
  padding-top: 75px;
  padding-bottom: 75px;
}

.light_silver {
  background: #f9f9f9;
}

.theme_bg {
  background: #38c8a8;
}

.margin_top_30 {
  margin-top: 30px !important;
}

.full {
  display: flex;
  flex-direction: inherit;
}

.loader_bg {
  position: fixed;
  z-index: 9999999;
  background: black;
  width: 100%;
  height: 100%;
}

.loader {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 280px;
  }
}

/*---------------------------------------------------------------------
    layout new css 
---------------------------------------------------------------------*/

.right_header_info {
  width: 100%;
  float: left;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    float: right;

    li {
      display: inline;
      font-size: 18px;
      margin-left: 20px;
    }
  }
}

.menu_icon-sign {
  background-color: #b3a063 !important;
  border-radius: 6px;
  width: 105px;
  height: 38px;
  margin: 0 auto;
  margin-top: 32px;
}

.menu_iconb {
  background-color: #b3a063 !important;
  border-radius: 6px;
  width: 105px;
  height: 38px;

  :active {
    background-color: #b3a063 !important;
    border-radius: 6px;
    width: 105px;
    height: 38px;
    margin-right: 30px;
  }

  :hover {
    background-color: #b3a063 !important;
    border-radius: 6px;
    width: 105px;
    height: 38px;
  }
}

.link_footer {
  display: flex;
  justify-content: space-evenly;
  padding-top: 30px;
  margin-bottom: 96px;
}

.link-fotter {
  width: 55%;
  margin: 0 auto;
}

.slider_cont_section {
  h4 {
    color: #f36b2a;
    text-transform: uppercase;
    font-size: 48px;
    font-weight: 700;
    padding: 0;
    line-height: normal;
  }

  h3 {
    color: #fff;
    text-transform: uppercase;
    font-size: 135px;
    font-weight: 700;
    padding: 0;
    line-height: 125px;
    margin-bottom: 35px;
  }

  p {
    color: #fff;
    font-size: 18px;
    font-weight: 300;
    padding: 0;
    line-height: normal;
    margin-bottom: 50px;
  }
}

.center {
  display: flex;
  justify-content: center;
}

#slider_main {
  padding-top: 205px;
}

.top_section div.button_section {
  display: flex;
}

.padding3 {
  padding: 0px;
}

.header {
  background: transparent;
  padding: 38px 0px;
  z-index: 99;
  width: 100%;
}

.children {
  min-height: 100vh;
}

.logo a {
  font-size: 40px;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  line-height: 40px;
}

.center-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 25%;
  padding: 20px;
}

.banner_main {
  position: relative;
  margin-bottom: -1px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  img {
    width: 100%;
    border-top: 3px solid transparent;
    border-image: linear-gradient(to right, transparent, #b3a063, transparent) 1;
    border-image-slice: 1;
  }
}

.text-bg {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  margin-top: -45px;
}

.banner_main .text-bg a {
  height: 50px;
  width: 125px;
  background: #fd7e14;
  color: #ffffff;
  font-size: 17px;
  display: inline-block;
  text-align: center;

  /* Center text horizontally */
  line-height: 35px;

  /* Center text vertically by matching line height to container height */
  margin-top: 25px;
  font-weight: 500;
  transition: all 0.4s ease-in;
  box-shadow: 0px 0px 50px 0px #fd7e14;

  &:hover {
    color: #ffffff;
    background: var(--yellow-500, #ffc107);
    box-shadow: 0px 0px 50px 0px #ffc008;
  }
}

/* .titlepage {
  padding-top: 60px;
} */

.titlepage {
  h4 {
    border-top: 3px solid transparent;
    border-image: linear-gradient(to right, transparent, #b3a063, transparent) 1;
    border-image-slice: 1;
    color: white;
    font-size: 28px;
    padding-top: 94px;
  }

  h3 {
    border-top: 3px solid transparent;
    border-image: linear-gradient(to right, transparent, #b3a063, transparent) 1;
    border-image-slice: 1;
    font-weight: bold;
    color: #f8eca6;
    line-height: 66px;
    font-size: 40px;
    text-align: center;
    background-image: linear-gradient(to bottom, white, #fff, #fff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding-bottom: 65px;
    padding-top: 65px;
  }

  h2 {
    font-weight: bold;
    color: #f8eca6;
    line-height: 66px;
    font-size: 40px;
    text-align: center;
    background-image: linear-gradient(to bottom, white, #fff, #fff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .titlepage h2 {
    padding-top: 10px !important;
  }
  span {
    border: #f0e7b5 solid 1px;
    width: 100%;
    max-width: 450px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
  }
}

.leaderboard {
  color: white;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  text-align: center;
  margin: 0px auto;
  font-weight: 900;
  padding-bottom: 35px;
  text-decoration: underline;
}

.leaderboard-home {
  color: white;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  text-align: center;
  margin-top: 70px;
  font-weight: 900;
  text-decoration: underline;
}

/** casino section **/

.game-title {
  color: white !important;
  font-size: 50px;
  font-weight: 600;
  justify-content: center;
  text-align: center;
  margin: 0px auto;
  font-weight: 900;
  padding-bottom: 35px;
  padding-top: 96px;

  h3 {
    color: white;
  }
}

.casino {
  min-height: 100vh;
}

.casino .game_box {
  margin: 20px;

  figure {
    margin: 0;

    img {
      width: 100%;
      border-radius: 8px;
    }
  }
}

.tournament-game {
  position: relative;
}

.casino .tournament-game {
  .game_box {
    outline: 6px solid #fd7e14;
    border-radius: 8px;
  }

  .game h3 {
    color: #fd7e14;
  }
}

.main-header {
  min-height: 117px;
}

.tournament-game .tournament-game-header {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 5px solid #fd7e14;
  border-radius: 5px;
  color: white;
  top: 13px;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  font-size: 16px;
  background: #fd7e14;
}

.tournament-game-header {
  display: none;
}

.padding_bottom {
  margin-bottom: 30px;
}

.casino .game {
  display: flex;
  align-items: center;

  /* Center vertically */
  justify-content: center;

  /* Center horizontally */
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  height: 50px;
  margin: 0 auto;

  h3 {
    text-align: center;
    color: white;
  }
}

/** end casino section **/

.d_flex {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

/** licens section **/

.licens {
  padding-top: 90px;
}

.licens_Carousel .carousel-caption {
  position: inherit;
  background-image: linear-gradient(to right, #bc883d, #f5e47b, #bc883d);
  border-radius: 30px;
  margin: 0 80px;
  padding: 30px 40px;
}

.licens_box figure {
  margin: 0;

  img {
    width: 100%;
  }
}

.img-lisence {
  min-width: 200px;
  margin-bottom: 32px;
}

.licens_box {
  text-align: left;
  background: #fff;
  box-shadow: #ddd 0px 0px 10px 0px;
  padding: 0px 40px 40px 40px;
}

.licens_Carousel {
  .test_box {
    .jons {
      text-align: left;

      h4 {
        font-size: 40px;
        color: #1b0905;
        line-height: 50px;
        display: inline-block;
        font-weight: bold;
        text-transform: uppercase;
      }

      figure {
        margin: 0;
      }
    }

    p {
      font-size: 17px;
      line-height: 28px;
      color: #1b0905;
      text-align: left;
      padding: 20px 0;
    }
  }

  padding: 0 20px;
}

.read_more {
  width: 191px;
  background: #1f0b04;
  padding: 9px 0px;
  color: #f3e179;
  font-size: 17px;
  display: inline-block;
  text-align: center;
  margin-top: 20px;
  text-transform: uppercase;
  font-weight: 500;
  transition: all 0.4s ease-in;
  float: left;

  &:hover {
    background: #fff;
    color: #1f0b04 !important;
    transition: all 0.4s ease-in;
    border-radius: 20px;
  }
}

.licens_Carousel .carousel-indicators {
  display: none;
}

#licens {
  .carousel-control-prev,
  .carousel-control-next {
    width: 62px;
    height: 62px;
    background: #deae25;
    opacity: 1;
    font-size: 28px;
    color: #fff;
    top: 50%;
    border-radius: 30px;
    background-image: linear-gradient(to right, #bc883d, #f5e47b, #bc883d);
  }
}

.lorem {
  font-size: 17px;
  line-height: 28px;
  color: #fefcfb;
  text-align: center;
  font-weight: 500;
  padding-top: 60px;
  padding-bottom: 90px;
}

ul.spinit {
  text-align: center;
  background-color: #1f0b04;
  box-shadow: #0d0303 0px 0px 6px -1px;
  padding: 20px 0px;

  li {
    display: inline-block;
    text-align: center;

    a {
      display: inline-block;
      font-weight: 501;
      font-size: 24px;
      line-height: 20px;
      text-transform: uppercase;
      background-image: linear-gradient(to bottom, #bc883d, #f5e47b, #bc883d);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin: 0px 39px;
      padding-bottom: 5px;

      &:hover {
        border-bottom: #fff solid 1px;
      }
    }
  }
}

.two_box {
  padding-top: 90px;

  .many_box_img {
    padding: 20px;
    background-image: linear-gradient(to bottom, #bc883d, #f5e47b, #bc883d);

    figure {
      margin: 0;

      img {
        width: 100%;
      }
    }
  }

  .many_box p {
    font-size: 17px;
    line-height: 28px;
    color: #fefcfb;
    text-align: left;
    font-weight: 500;
  }
}

/** end licens section **/

/** customer section **/

.customer {
  background-color: #000000;
  padding-top: 90px;
}

#supertokens-root {
  background-color: #000000;
  width: 100vw;
  height: 100vh;
}

.customer .customer_text {
  text-align: center;
}

.customer_img {
  max-height: 155px;
}

.customer .customer_text {
  p {
    font-size: 17px;
    line-height: 35px;
    color: #0c0c0b;
    text-align: center;
    font-weight: 500;
    background-color: #d7d7d4;
    padding: 90px 45px 150px 45px;
  }

  .customer_box {
    margin-top: -75px;

    i {
      padding: 72px 10px 75px 10px;
      background-image: linear-gradient(to bottom, #b3a063, #b3a063, #b3a063);
    }

    h4 {
      font-size: 24px;
      padding: 25px 0px 10px 0px;
      font-weight: 500;
      background-image: linear-gradient(to bottom, #bc883d, #f5e47b, #bc883d);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    span {
      color: #fff;
      font-size: 17px;
      line-height: 20px;
      text-transform: uppercase;
    }
  }
}

/** customer section **/

/** reqeste section **/

.reqeste {
  background-color: #1b0905;
  padding: 85px 0 90px 0;
}

#cochang *::placeholder {
  color: #f9fcfd;
}

.form_main {
  .form_control {
    border: inherit;
    border-bottom: #ddd solid 1px;
    background-color: transparent;
    font-size: 17px;
    margin-bottom: 30px;
    border-radius: inherit;
    padding-bottom: 10px;
    color: #f9fcfd;
    width: 100%;
  }

  .send_btn {
    font-size: 17px;
    border: #fff solid 2px;
    background-color: transparent;
    color: #fff;
    padding: 10px 60px;
    border-radius: 35px;
    text-align: center;
    margin: 0 auto;
    display: block;
    margin-top: 20px;

    &:hover {
      background-color: #fff;
      color: #000000;
    }
  }
}

/**
.form_main .textarea {
margin-bottom: 25px;
font-size: 17px;
color: #f9fcfd;
background-color: transparent;
width: 100%;
border: inherit;
border-bottom: #ddd solid 1px;
border-radius: inherit;}
**/

/** end reqeste section **/

/** footer section **/

.footer-link {
  display: flex;

  /* list-style: none; */
  justify-content: space-evenly;
}

.footer {
  border-top: 3px solid transparent;
  border-image: linear-gradient(to right, transparent, #b3a063, transparent) 1;
  border-image-slice: 1;
  padding-top: 50px;
}

.link-footer {
  display: flex;
  justify-content: space-evenly;
}

.newtetter {
  padding-right: 60px;

  .tetter {
    border-bottom: #ddd solid 1px !important;
    background: transparent;
    padding: 7px 0px;
    border-radius: inherit;
    font-size: 17px;
    color: #fff;
    border: inherit;
    width: 100%;
  }
}

.submit {
  margin-top: 15px;
  background: transparent;
  padding: 5px 45px;
  color: #5d5756;
  transition: all 0.4s ease-in;
  float: right;
  margin-bottom: -34px;
  border: #a19a98 solid 2px;
  border-radius: 39px;
  cursor: pointer;
  font-size: 17px;
  position: relative;
  font-weight: 500;

  &:hover {
    background: #c9c08e;
    color: #000;
    border-radius: 20px;
    transition: all 0.4s ease-in;
  }
}

.player p {
  display: inline-block;
  color: #f8eca6;
  text-transform: uppercase;
  line-height: 27px;
  font-weight: 700;
  background-image: linear-gradient(to bottom, #ffffff, #f4f4f4, #f5f2ef);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 10px;
  font-size: 16px;
}

.address {
  h3 {
    color: #f8eca6;
    text-transform: uppercase;
    font-size: 25px;
    line-height: 27px;
    font-weight: 700;
    padding-bottom: 20px;
    background-image: linear-gradient(to bottom, #b3a063, #b3a063, #b3a063);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  p {
    color: #fff;
    padding-bottom: 15px;
  }
}

ul {
  &.Menu_footer li {
    padding-bottom: 6px;
    display: block;
    color: #3e7f21;
    position: relative;

    a {
      display: block;
      color: #ffffff;
      font-size: 17px;
      line-height: 36px;
      font-weight: 500;

      &.active {
        color: #f8eca6;
      }

      &:hover {
        color: #f5e47b;
      }
    }
  }

  &.Links_footer {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    float: left;
    font-family: sans-serif;

    li {
      color: #ffffff;
      display: block;
      position: relative;
      font-weight: 500;
      font-size: 16px;
      line-height: 36px;
    }
  }

  &.loca li {
    color: #fcfbfb;
    padding-bottom: 10px;
    display: flex;
    font-size: 16px;
    line-height: 20px;
    flex-wrap: wrap;
    align-items: center;

    a {
      padding-right: 16px;
      color: #ee3e0f;
      margin-top: 4px;
    }
  }
}

.copyright {
  padding: 0px 0px;
  margin-top: 5px;

  p {
    color: #000;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
  }

  a {
    color: #000;

    &:hover {
      color: #fff;
    }
  }
}

#map {
  height: 100%;
  min-height: 480px;
}

/** end footer section **/

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

#sidebar {
  width: 280px;
  position: fixed;
  top: 0;
  left: -280px;
  height: 100vh;
  z-index: 1000;
  background: #fff;
  color: #fff;
  transition: all 0.3s;
  overflow-y: scroll;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);

  &.active {
    left: 0;
  }
}

#dismiss {
  width: 35px;
  height: 35px;
  line-height: 34px;
  text-align: center;
  background: #1b0905;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;

  &:hover {
    background: #bc883d;
    color: #fff;
  }
}

.overlay {
  display: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 998;
  opacity: 0;
  transition: all 0.5s ease-in-out;

  &.active {
    display: block;
    opacity: 1;
  }
}

#sidebarCollapse {
  background: transparent;
  padding: 0;
  border: none;
}

#sidebar {
  .sidebar-header {
    padding: 20px;
    background: #6d7fcc;
  }

  ul {
    &.components {
      padding: 55px 0;
    }

    p {
      color: #fff;
      padding: 10px;
    }

    li {
      a {
        padding: 10px 25px;
        font-size: 1.3em;
        display: block;
        font-weight: 300;

        &:hover {
          color: #fff;
          background: #bc883d;
        }
      }

      &.active > a {
        color: #000;
        background: #bc883d;
      }
    }
  }
}

a {
  &[aria-expanded='true'] {
    color: #000;
    background: #bc883d;
  }

  &[data-toggle='collapse'] {
    position: relative;
  }
}

.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

ul {
  ul a {
    font-size: 0.9em !important;
    padding-left: 30px !important;
    background: #6d7fcc;
  }

  &.CTAs {
    padding: 20px;

    a {
      text-align: center;
      font-size: 0.9em !important;
      display: block;
      border-radius: 5px;
      margin-bottom: 5px;
    }
  }
}

a {
  &.download {
    background: #fff;
    color: #7386d5;
  }

  &.article {
    background: #6d7fcc !important;
    color: #fff !important;

    &:hover {
      background: #6d7fcc !important;
      color: #fff !important;
    }
  }
}

.winner {
  background-image: linear-gradient(to bottom, #000000, #000000, #000000);
  padding: 20px;
  border-radius: 40px;

  thead tr th {
    background-color: rgba(0, 0, 0, 0);
    border-bottom: #000 solid 1px;
    padding: 10px;
    font-size: 29px;
    color: wheat;
  }

  tbody tr td {
    background-color: rgba(0, 0, 0, 0);
    border-bottom: 3px solid #b3a063;
    color: #ffffff;
    font-size: 25px;
    line-height: 27px;
    padding: 20px;
    border: none;
  }

  td {
    text-transform: uppercase;
    font-size: 25px;
    line-height: 27px;
    font-weight: 700;
  }

  tr {
    border: 4px solid #396fb7;
  }
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;

  /* Your other styling for the table */
}

th,
td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #f2f2f2;
}

.ordered-number-cell {
  text-align: center;
}

.ordered-number {
  display: inline-block;
  width: 20px;

  /* Adjust this width to your preference */
}

.first-player-row {
  background-color: #d22740;

  /* Set the background color you want for the first player */
  /* Additional styling for the first player row */
}

.other-player-row {
  background-color: #11173f;

  /* Set the background color for other players */
  /* Additional styling for other player rows */
}

.player-name {
  display: flex;
  flex-direction: column;
}

.ordered-number {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.player-info {
  display: flex;
  flex-direction: column;
}

.player-name {
  font-weight: bold;
}

.player-surname {
  color: #666;
}

.game-title-style {
  text-align: center;
  color: white;
  font-weight: 700;
  font-size: 40px;
}

.container {
  position: relative;
  text-align: center;

  /* Center-align the text horizontally */
}

.centered-text {
  color: white;
  position: absolute;
  bottom: 100px;
  left: 50%;

  /* Place the text horizontally at 50% from the left of the container */
  transform: translate(-50%, -50%);

  /* Center the text both vertically and horizontally */
  padding: 10px;

  /* Optional: Add padding to the text for better readability */
}

.title-img {
  font-size: 55px;
  font-weight: 700;
  text-shadow: -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000, 2px 2px 0 #000;
}

.date-img {
  font-size: 22px;
  font-weight: 600;
  font-family: sans-serif;
  position: relative;
  text-align: center;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

/* Styles for table rows and cells */

tr {
  border-bottom: 1px solid #ccc;
}

td,
th {
  padding: 8px;
  text-align: left;
}

/* Media query for smaller screens (all mobile devices) */
@media (max-width: 767px) {
  /* Hide table headers on smaller screens */

  th {
    display: none;
  }

  /* Add some spacing between table rows on smaller screens */

  tr {
    margin-bottom: 10px;
    display: block;
    border: 1px solid #ccc;
  }

  /* Style each cell on smaller screens */

  td {
    display: block;
  }

  /* Style specific cells as needed */

  .ordered-number-cell {
    font-weight: bold;
  }

  /* Add spacing and style to cell content */

  td::before {
    content: attr(data-label);
    font-weight: bold;
    display: inline-block;
    width: 100px;

    /* Adjust the width as needed */
  }
}

.header-end {
  display: flex;
}

.img-logo {
  width: 150px;
}

.img-logo-footer {
  min-width: 220px;
}

.icon-style {
  color: #b3a063;
  margin-right: 10px;
}

.tournament-bg {
  background-image: url('~/public/images/banner.png');
  background-position: top;

  /* Center the image */
  background-repeat: no-repeat;

  /* Do not repeat the image */
  padding-bottom: 20%;
  background-size: contain;
}

.all-games {
  text-decoration: underline;
  color: white;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  margin-top: 32px;
  margin-bottom: 32px;
}

.home-bg {
  background-image: url('~/public/images/bg_main.png');
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
}

.first-leader {
  padding: 16px;
  border: 3px solid #396fb7;
  background-color: #d22740;
  color: #fff;
}

.other-leader {
  border: none;
  color: #fff;
  padding: 16px;
  border: 3px solid #396fb7;
  background-color: #11173f;
}

.padding-row {
  padding: 10px;
}

.centered_text {
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  h1 h2 h3 h4 h5 {
    font-weight: 300;
  }
}

@media (min-width: 576px) {
  .homepage-margin {
    padding-bottom: 120px;
  }

  .centered-text {
    bottom: 100px;
    color: #fff;
    left: 50%;
    padding: 15px;
    position: absolute;
    transform: translate(-50%, -10%);
  }
}

@media (min-width: 768px) {
  .homepage-margin {
    padding-bottom: 235px;
  }
}

@media (min-width: 992px) {
  .homepage-margin {
    padding-bottom: 360px;
  }
}

@media (min-width: 1200px) {
  .homepage-margin {
    padding-bottom: 460px;
  }
}

.overlay-image {
  position: absolute;
  bottom: 0;

  /* Adjust to position the image where you want it */
  left: 50%;

  /* Center the image horizontally */
  transform: translateX(-50%);

  /* Center horizontally */
  max-width: 100%;

  /* Ensure the image doesn't exceed its container */
}

.font_text_1 {
  font-size: 40px;
}

.font_text_2 {
  font-size: 24px;
}

.font_text_3 {
  font-size: 16px;
}

.font_text_4 {
  font-size: 24px;
}

[data-supertokens~='forgotPasswordLink'] {
  display: none;
}

.modal-background {
  background-image: url('~/public/images/modal.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px;
  border: 4px solid var(--colors-phoenix-7, #b3a063);
  box-shadow: 0px 0px 28.90625px 0px #fd7e14;
}

.container-size {
  padding-top: 31.4%;
  padding-bottom: 31.4%;
  color: white;
}

.modal-open {
  overflow: hidden;
}

.btn-close {
  color: white !important;
}

.btn-tournament {
  background: #fd7e14;
  box-shadow: 0 0 50px 0 #fd7e14;
  color: #fff;
  display: inline-block;
  font-size: 17px;
  font-weight: 500;
  height: 50px;
  line-height: 35px;
  text-align: center;
  transition: all 0.4s ease-in;
  width: 125px;
  border: none;
}

.btn-tournament:hover {
  color: #ffffff;
  background: var(--yellow-500, #ffc107);
  box-shadow: 0px 0px 50px 0px #ffc008;
}

.btn-tournament:checked {
  color: #ffffff;
  background-color: #fd7e14 !important;
  border-color: #fd7e14 !important;
}

.btn-check:checked + .btn:active,
.btn:first-child:active,
.btn.active {
  color: var(--bs-btn-active-color);
  background-color: #fd7e14 !important;
  border-color: #fd7e14 !important;
}
.modal-header .btn-close {
  color: white !important;
}

.text-tournament {
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-shadow: -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000, 2px 2px 0 #000;
}

.date-tournament {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  font-family: Arial, Helvetica, sans-serif;
}

.close {
  color: white !important;
  position: absolute;
  right: 0;
  background: no-repeat;
  top: 10px;
}
.alice-carousel {
  min-height: 324px;
}

.alice-carousel__prev-btn-item,
.alice-carousel__next-btn-item {
  display: inline-block;
  cursor: pointer;
  padding: 5px;
  margin: 0;
  color: #f8f9fa !important;
  font-weight: 900;
}

.alice-carousel__prev-btn-wrapper {
  text-align: end;
}

.alice-carousel__next-btn-wrapper {
  text-align: start;
}
.prev-btn {
  color: white;
}

.next-btn {
  color: white;
}

.text-follow {
  color: #b3a063 !important;
}

.win-amount {
  display: flex !important;
  justify-content: center !important;
}

.justify-content-center {
  justify-content: center !important;
  padding-bottom: 15px;
}

.row-padding {
  padding-bottom: 96px !important;
}

.parent [data-supertokens='formRow']:last-of-type {
  padding: 0 !important;
}

[data-supertokens~='container'] {
  /* Add your default styles here */
  /* For example, you may want to set a max-width for larger screens */
  max-width: 100%;
}

/* Media query for iPhones or mobile devices */
@media (max-width: 767px) {
  /* Override styles for .container on mobile devices */
  [data-supertokens~='container'] {
    width: 100vw; /* Set width to viewport width (vw) */
    max-width: none; /* Remove any max-width restriction */
  }
}

[data-supertokens~='input'] {
  background-color: white;
  touch-action: manipulatio !important;
  zoom: 1;
}

.timer {
    background-color: rgba(0,0,0,.5);
    border-radius: 4px;
    color: #fff;
    display: inline-block;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    height: auto;
    width: fit-content;
    line-height: normal;
    padding: 2px 4px;
    position: absolute ; 
    right: 16px;
    text-align: right;
    top: 10px;
    width: fit-content;
    z-index: 999;
    left: auto;
}

.background-iframe *{
  background-color: black;
}