/*--------------------------------------------------------------------- File Name: responsive.css ---------------------------------------------------------------------*/

/*------------------------------------------------------------------- 991px x 768px ---------------------------------------------------------------------*/

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-menu ul > li a {
    padding: 20px 20px 20px;
  }

  .header-search {
    padding: 15px 0px;
  }
}

/*------------------------------------------------------------------- 767px x 599px ---------------------------------------------------------------------*/

@media only screen and (min-width: 599px) and (max-width: 767px) {
  .logo {
    text-align: center;
  }

  .cart-content-right {
    padding-bottom: 5px;
  }

  .mg {
    margin: 0px 0px;
  }

  .menu-area-main {
    height: 256px;
    overflow-y: auto;
  }

  .megamenu > .row [class*='col-'] {
    padding: 0px;
  }

  .menu-area-main .megamenu {
    .men-cat,
    .women-cat,
    .el-cat {
      padding: 0px 15px;
    }
  }

  .mean-container .mean-nav ul li a.mean-expand {
    height: 19px;
  }

  .category-box.women-box {
    display: none;
  }

  .cart-box {
    display: inline-block;
    margin: 0px 30px;
  }

  .wish-box {
    float: none;
    margin: 0px 30px;
    display: inline-block;
  }

  .menu-add,
  .category-box {
    display: none;
  }

  .mean-container .mean-nav ul li {
    ol {
      padding: 0px;
    }

    a {
      padding: 10px 20px;
      width: 94.8%;
    }

    li {
      a {
        width: 92%;
        padding: 1em 4%;
      }

      li a {
        width: 100%;
      }
    }
  }

  .header-search {
    padding: 15px 0px;
  }

  #collapseFilter.d-md-block {
    padding: 30px 0px;
  }
}

/*------------------------------------------------------------------- 599px x 280px ---------------------------------------------------------------------*/

@media only screen and (min-width: 280px) and (max-width: 599px) {
  .cart-content-right {
    padding-bottom: 5px;
  }

  .megamenu > .row [class*='col-'] {
    padding: 0px;
  }

  .menu-area-main .megamenu {
    .men-cat,
    .women-cat,
    .el-cat {
      padding: 0px 15px;
    }
  }

  .mean-container {
    .mean-nav ul li {
      a {
        padding: 1em 4%;
        width: 92%;
      }

      li a {
        width: 90%;
        padding: 1em 5%;
      }
    }

    .sub-full.megamenu-categories ol li a {
      padding: 5px 0px;
      text-transform: capitalize;
      width: 100%;
    }
  }

  .megamenu .sub-full.megamenu-categories .women-box .banner-up-text a {
    width: auto;
    border: none;
    float: none;
  }

  .menu-area-main {
    height: 290px;
    overflow-y: auto;
  }

  .mean-container .mean-nav ul li a.mean-expand {
    top: 0;
  }
}

@media (min-width: 1200px) and (max-width: 1500px) {
}

@media (min-width: 992px) and (max-width: 1199px) {
  ul.spinit li a {
    margin: 0px 19px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  ul.spinit li a {
    font-size: 18px;
    line-height: 16px;
    margin: 0px 14px;
  }

  .casino .game {
    max-width: 160px;
  }

  .font_text_1 {
    font-size: 20px;
  }

  .font_text_2 {
    font-size: 16px;
  }

  .font_text_3 {
    font-size: 8px;
  }

  .font_text_4 {
    font-size: 16px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .right_header_info {
    margin-top: -54px;
  }

  .header {
    padding-bottom: 25px;
    padding-top: 35px;
  }

  .licens_Carousel {
    .carousel-caption {
      margin: 0px 44px;
    }

    .test_box .jons {
      margin-top: 30px;
    }
  }

  ul.spinit li a {
    font-size: 15px;
    margin: 0px 5px;
    line-height: 15px;
  }

  .two_box .many_box p {
    padding-top: 30px;
  }

  .titlepage h2 {
    line-height: 40px;
    font-size: 35px;
  }

  .newtetter .tetter,
  .margin_bottom1 {
    margin-bottom: 30px;
  }

  .text-bg {
    margin-top: -20px;
  }
}

@media (max-width: 575px) {
  .right_header_info {
    margin-top: -54px;
  }

  .header {
    padding-bottom: 25px;
    padding-top: 25px;
  }

  .logo {
    width: 134px;
  }

  .banner_main img {
    height: 250px;
  }

  .text-bg {
    margin-top: -20px;
  }

  .licens_Carousel {
    .carousel-caption {
      margin: 0px;
      padding: 30px 20px;
    }

    padding: 0;

    .test_box .jons {
      h4 {
        font-size: 32px;
      }

      margin-top: 30px;
    }
  }

  .tournament-bg {
    background-position: bottom !important;
    padding-bottom: 44% !important;
  }

  .home-bg {
    background-image: none !important;
  }

  .address {
    text-align: center;
    padding-bottom: 10%;
  }

  .two_box .many_box p {
    padding-top: 30px;
  }

  ul.spinit li a {
    font-size: 14px;
    margin: 0px 11px;
    line-height: 14px;
  }

  .titlepage h2 {
    line-height: 37px;
    font-size: 30px;
  }

  .customer .customer_text p {
    padding: 90px 20px 150px 20px;
  }

  .newtetter {
    padding: 0;

    .tetter {
      margin-bottom: 30px;
    }
  }

  .margin_bottom1,
  ul.Menu_footer {
    margin-bottom: 30px;
  }

  #licens {
    .carousel-control-prev,
    .carousel-control-next {
      display: none;
    }
  }
}
@media (max-width: 667px) {
  .title-img {
    font-size: 34px !important;
  }
}

@media (max-width: 575.98px) {
  .slider_section {
    padding-top: 115px;
  }

  .title-img {
    font-size: 30px !important;
  }

  .date-img {
    font-size: 15px !important;
  }

  .centered-text {
    padding: 1px !important;
  }

  header {
    justify-content: center;
  }
}

@media (max-width: 375px) {
  .slider_section {
    padding-top: 200px;
  }
}

@media (max-width: 480px) {
  .slider_section {
    padding-top: 113px;
  }

  .title-img {
    font-size: 30px;
  }
}

@media (min-width: 991.5px) {
  .alice-carousel__dots {
    display: block;
  }
  .alice-carousel__prev-btn {
    display: none !important;
  }
  .alice-carousel__next-btn {
    display: none !important;
  }
}

@media (max-width: 992px) {
    .alice-carousel__dots {
      display: none;
    }
    .alice-carousel__prev-btn {
      display: inline-block;
      padding: 10px;
    }
    .alice-carousel__next-btn {
      display: inline-block;
      padding: 10px;
      text-align: initial;
    }
  }

  .alice-carousel__prev-btn-item{
    outline: 2px solid white;
    width: 30px;
  }

  .alice-carousel__next-btn-item{
    outline: 2px solid white;
    width: 30px;
  }
  @media (max-width: 376px) {
    .row_responsive > div[class^="col-sm-"] {
      width: 100%; /* Make all columns take up the full width */
     
    }
  }
  @media (max-width: 575px) {
    .row_responsive > div[class^="col-"] {
     
    }
  }
  @media (max-width: 376px) {
    .row_responsive {
      display: flex;
    
    }
    [data-supertokens~="container"] {
      width: auto !important;
  }
    .row_responsive > div[class^="col-"] {
      flex: 0 0 100%; /* Make columns take up 100% of the width */
      max-width: 100%; /* Ensure columns don't exceed 100% width */
      padding-right: 15px; /* Adjust for Bootstrap's gutter width */
      padding-left: 15px; /* Adjust for Bootstrap's gutter width */
    }
  }

  @media (max-width: 768px) {
    .Menu_footer li {
      margin: 5px;
    }
  
    .Menu_footer img {
      width: 24px;
      height: 24px;
    }
  }
  @media (max-width: 440px){

    [data-supertokens~="container"] {
      width: auto !important;
  }

  }

[data-supertokens~="container"] {
  font-family: "Rubik", sans-serif;
  margin: 12px auto;
  margin-top: 26px;
  margin-bottom: 26px;
  text-align: center;
  border-radius: 8px;
}



[data-supertokens~=container] {
  /* Add your default styles here */
  /* For example, you may want to set a max-width for larger screens */
  max-width: 100% !important;
}

/* Media query for iPhones or mobile devices */
@media (max-width: 767px) {
  /* Override styles for .container on mobile devices */
  [data-supertokens~=container] {
    width: 100vw !important; /* Set width to viewport width (vw) */
    max-width: none; /* Remove any max-width restriction */
  }
}






